import { createGlobalStyle } from "styled-components";
import "./base.css";
import "./type.css";
import {
    accentColorConfirmation,
    accentColorDanger,
    accentColorInfo,
    accentColorLink,
    accentColorLinkActive,
    accentColorLinkVisited,
    accentColorPrimary,
    accentColorWarning,
    backgroundColorActive,
    backgroundColorAlt,
    backgroundColorDisabled,
    backgroundColorHover,
    backgroundColorLevel1,
    backgroundColorLevel2,
    backgroundColorLevel3,
    backgroundColorLevel4,
    backgroundColorLevel5,
    backgroundColorLevelCurrent,
    borderColorPrimary,
    borderColorSecondary,
    borderColorTertiary,
    boxShadowDetached,
    boxShadowOverlaid,
    boxShadowPoppedOut,
    boxShadowRaised,
    highlightBlue,
    highlightBrown,
    highlightDarkBlue,
    highlightGreen,
    highlightGrey,
    highlightOrange,
    highlightPink,
    highlightPurple,
    highlightRed,
    highlightYellow,
    textColorInverted,
    textColorPrimary,
    textColorSecondary,
    textColorTertiary,
    getAppearanceConstant,
} from "@churchofjesuschrist/eden-style-constants";

const highlightColors = {
    blue: highlightBlue,
    brown: highlightBrown,
    dark_blue: highlightDarkBlue,
    gray: highlightGrey,
    green: highlightGreen,
    orange: highlightOrange,
    pink: highlightPink,
    purple: highlightPurple,
    red: highlightRed,
    yellow: highlightYellow,
    clear: backgroundColorLevelCurrent,
};

/**
 * Unity doesn't have underline highlight colors defined and the normal highlight colors are too light to be used as underlines. The below removes the alpha hex from the provided highlight colors which makes them stronger versions of the same and more suitable for underlines. This isn't officially blessed so we may need to re-visit this at some point.
 */
let underlineLightCode = [];
let underlineDarkCode = [];
for (const [key, value] of Object.entries(highlightColors)) {
    // Get the unity values for the highlight version
    const curValues = getAppearanceConstant(value);

    underlineLightCode.push(
        `--${key}Underline: ${curValues.light.match(/#[a-fA-F0-9]{6}/)?.[0]};`
    );
    underlineDarkCode.push(
        `--${key}Underline: ${curValues.dark.match(/#[a-fA-F0-9]{6}/)?.[0]};`
    );
}

/* Eden doesn't output light versions of their CSS variables (we aren't technically supposed to use them directly anyway) as they are meant to be imported in react components.  We use a lot of .css files that can't import those so we need to define the light versions of these variables. The following imports the appearance constants and re-sets them as local variables we can use using the name of the export as the variable name.  They output in the format of `var(--accent-color-confirmation, #64A53D)`. The `--accent-color-confirmation` is set for the dark mode, but not light mode, and the fallback color is used for light mode when the dark is not set. This allows us to use `--accentColorConfirmation` in .css files and `accentColorConfirmation` in react files leaving the `--accent-color-confirmation` to be set by eden based on dark mode usage.  */
const GlobalStyles = createGlobalStyle`
:root {
  --accentColorConfirmation:${accentColorConfirmation};
  --accentColorDanger:${accentColorDanger};
  --accentColorInfo:${accentColorInfo};
  --accentColorLink:${accentColorLink};
  --accentColorLinkActive:${accentColorLinkActive};
  --accentColorLinkVisited:${accentColorLinkVisited};
  --accentColorPrimary:${accentColorPrimary};
  --accentColorWarning:${accentColorWarning};
  --backgroundColorActive:${backgroundColorActive};
  --backgroundColorAlt:${backgroundColorAlt};
  --backgroundColorDisabled:${backgroundColorDisabled};
  --backgroundColorHover:${backgroundColorHover};
  --backgroundColorLevel1:${backgroundColorLevel1};
  --backgroundColorLevel2:${backgroundColorLevel2};
  --backgroundColorLevel3:${backgroundColorLevel3};
  --backgroundColorLevel4:${backgroundColorLevel4};
  --backgroundColorLevel5:${backgroundColorLevel5};
  --backgroundColorLevelCurrent:${backgroundColorLevelCurrent};
  --borderColorPrimary:${borderColorPrimary};
  --borderColorSecondary:${borderColorSecondary};
  --borderColorTertiary:${borderColorTertiary};
  --boxShadowDetached:${boxShadowDetached};
  --boxShadowOverlaid:${boxShadowOverlaid};
  --boxShadowPoppedOut:${boxShadowPoppedOut};
  --boxShadowRaised:${boxShadowRaised};
  --highlightBlue:${highlightBlue};
  --highlightBrown:${highlightBrown};
  --highlightDarkBlue:${highlightDarkBlue};
  --highlightGreen:${highlightGreen};
  --highlightGrey:${highlightGrey};
  --highlightOrange:${highlightOrange};
  --highlightPink:${highlightPink};
  --highlightPurple:${highlightPurple};
  --highlightRed:${highlightRed};
  --highlightYellow:${highlightYellow};
  --textColorInverted:${textColorInverted};
  --textColorPrimary:${textColorPrimary};
  --textColorSecondary:${textColorSecondary};
  --textColorTertiary:${textColorTertiary};
  ${underlineLightCode.join("\n")}
}
@media (prefers-color-scheme: dark) {
    :root {
        ${underlineDarkCode.join("\n")}
    }
}
`;

const NormalizeCss = ({ children }) => (
    <>
        <GlobalStyles />
        {children}
    </>
);

export default NormalizeCss;
